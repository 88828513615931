import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-lista-asociaciones',
  templateUrl: './lista-asociaciones.component.html',
  styleUrls: ['./lista-asociaciones.component.css']
})
export class ListaAsociacionesComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
